const paymentOptionsTitlesEnum = Object.freeze({
  SINGLE_PAY: "Single Pay",
  SMILE_PAY: "SmilePay",
  PURCHASE_LATER: "Not purchasing yet"
});

const singlePayPaymentDescriptions = ['Biggest savings.', '30-Day Money-Back Promise.']
const purchaseLaterPaymentDescriptions = ['Purchase aligners later.']
const smilePayPaymentDescriptions =
    [
    '{downPayment} {downPaymentTerm}, {monthly-price}/month or less for {term} months ({total-price} total).<sup>1<sup>',
        'No credit checks. No forms to fill out. Everyone is approved.',
        '30-Day Money-Back Promise.'

    ]

const smilePayLabelConfig = {
  svg: [
    'M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z',
  ],
  labelText: "Most Popular",
  primaryColor: "#ddccff",
  secondaryColor: "#5713ff"
}

const singlePayLabelConfig = {
  svg: [
    // Outer circle
    'M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z',
    // Vertical line
    'M12.25 5.58325V18.4166',
    // Dollar Sign
    'M15.1667 7.91675H10.7917C10.2502 7.91675 9.73088 8.13185 9.34799 8.51474C8.9651 8.89763 8.75 9.41693 8.75 9.95842' +
    'C8.75 10.4999 8.9651 11.0192 9.34799 11.4021C9.73088 11.785 10.2502 12.0001 10.7917 12.0001H13.7083C14.2498 12.0001 14.7691 12.2152 15.152 12.5981' +
    'C15.5349 12.981 15.75 13.5003 15.75 14.0417C15.75 14.5832 15.5349 15.1025 15.152 15.4854C14.7691 15.8683 14.2498 16.0834 13.7083 16.0834H8.75',
  ],
  labelText: "Best Value",
  primaryColor: "#5713ff",
  secondaryColor: "white"
}

const singlePayPaymentText = "Easy, one-time payment"

const smilePayPaymentText = "Convenient monthly payments"

const paymentOptionsEnum = Object.freeze({
  SINGLE_PAY: "singlepay",
  SMILE_PAY: "smilepay",
});

const countryCodesEnum = Object.freeze({
    AUSTRALIA: "AU",
    UNITED_STATES: "US",
});

const supportedCurrencyLocaleInfo = Object.freeze({
  USD: { locale: "en-us", currency: "USD"},
  AUD: { locale: "en-au", currency: "AUD"},
  CAD: { locale: "en-ca", currency: "CAD"},
});

const errorCodes = Object.freeze({
  RETRIEVING_PRODUCT_CREDIT_TERMS_FAILED: 'RetrievingProductCreditTermsFailed',
  RETRIEVING_FINANCING_TERMS_FAILED: 'RetrievingProductCreditTermsFailed',
});

const SELECTED_PAYMENT_PLAN_EVENT = 'SelectedPaymentPlanEvent';

const ENVIRONMENTS = Object.freeze({
    QA: 'qa',
    STAGE: 'stage',
    PROD: 'prod',
});
const ENV = process.env.VUE_APP_ENV || ENVIRONMENTS.QA;

export {
  paymentOptionsTitlesEnum,
  paymentOptionsEnum,
  countryCodesEnum,
  singlePayPaymentDescriptions,
  smilePayPaymentDescriptions,
  purchaseLaterPaymentDescriptions,
  supportedCurrencyLocaleInfo,
  errorCodes,
  SELECTED_PAYMENT_PLAN_EVENT,
  ENVIRONMENTS,
  ENV,
  singlePayLabelConfig,
  smilePayLabelConfig,
  singlePayPaymentText,
  smilePayPaymentText
}
